import React, {useEffect, useRef, useState} from 'react';
import {Fade, Paper, Slide, Typography} from '@mui/material';
import {makeStyles} from "@material-ui/core/styles";
import Box from "@mui/material/Box";

const useStyles = makeStyles((theme) => ({
    messageContainer: {
        padding: theme.spacing(2),
        margin: theme.spacing(2),
        backgroundColor: '#f0f0f0',
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[2],
        maxWidth: '80%', // Set maxWidth to 80%
        overflowWrap: 'anywhere',
        marginLeft: 'auto', // Center horizontally
        marginRight: 'auto', // Center horizontally
    }
}));

const WebSocketMessageDisplay = () => {
    const [messages, setMessages] = useState([]);
    const classes = useStyles();

    useEffect(() => {
        const webSocket = new WebSocket('ws://localhost:8076/websocket');

        webSocket.onmessage = (event) => {
            const message = JSON.parse(event.data);
            message.animate = true;
            setMessages((prevMessages) => [message, ...prevMessages]);
        };

        return () => {
            webSocket.close();
        };
    }, []);

    return (
        <div style={{marginTop: "70px"}}>
            <Box>
            {messages.map((message, index) => (
                <Fade in={message.animate} key={index}>

                    <Paper
                        elevation={3}
                        className={classes.messageContainer}
                    >
                        <Typography variant="subtitle1" gutterBottom>
                            Message Received:
                        </Typography>
                        <Typography variant="body1">
                            {JSON.stringify(message, null, 2)}
                        </Typography>
                    </Paper>
                </Fade>
            ))};
            </Box>
        </div>
    );
};

export default WebSocketMessageDisplay;
