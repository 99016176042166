import axios from "axios";
import React, {useEffect, useState} from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import {ListItem, ListItemAvatar, ListItemText} from "@mui/material";
import {Avatar, CardActions} from "@mui/material";
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import PushPinIcon from '@mui/icons-material/PushPin';
import HubIcon from '@mui/icons-material/Hub';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import {useNavigate} from 'react-router-dom';
import LoadingErrorComponent from "../components/LoadingErrorComponent";
import Button from "@mui/material/Button";
import ExploreOutlinedIcon from '@mui/icons-material/ExploreOutlined';
import BackendApiUrls from "../utils/BackendApiUrls";

function FederationMembersView() {
    const [members, setMembers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    async function getFileTreeNodesByIpfsCid(ipfsCid) {
        return ipfsCid ?
            axios.get(BackendApiUrls.IpfsCid.nodeTree(ipfsCid))
                .then((fileTreeNodesResponse) => fileTreeNodesResponse.data) :
            Promise.resolve({'fileCount': 0});
    }

    async function countFederationMemberFiles(ipfsCid) {
        return getFileTreeNodesByIpfsCid(ipfsCid)
            .then((fileTreeNodes) => fileTreeNodes.fileCount);
    }

    useEffect(() => {
        const fetchFederationMembersInformation = async () => {
            setIsLoading(true);
            setError(null);

            try {
                const response = await axios.get(BackendApiUrls.Federation.Members());
                for (const member of response.data) {
                    if (member.ipfsPeerRootCid) {
                        member.fileCount = await countFederationMemberFiles(member.ipfsPeerRootCid);
                    } else {
                        member.fileCount = '-';
                    }
                }
                setMembers(response.data);
            } catch (error) {
                console.error("Error fetching data:", error);
                setError(error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchFederationMembersInformation();
    }, []);

    return (
        <div>
            <LoadingErrorComponent isLoading={isLoading} error={error}/>
            {members.length > 0 && (
                <Grid container spacing={2}>
                    {members.map((member) => (
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <Card spacing={2}>
                                <CardContent>
                                    <Typography variant="h5" gutterBottom>
                                        {member.nickname ? member.nickname : member.ipfsClusterNodeName}
                                    </Typography>
                                    <Divider/>
                                    <Typography variant="body1">
                                        <ListItem alignItems="flex-start">
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <FingerprintIcon/>
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary="IPFS peer id"
                                                secondary={member.ipfsPeerId}
                                            />
                                        </ListItem>
                                        <ListItem alignItems="flex-start">
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <HubIcon sx={{color: 'inherit'}}/>
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary="IPFS cluster peer id"
                                                secondary={member.ipfsClusterPeerId}
                                            />
                                        </ListItem>
                                        <ListItem alignItems="flex-start">
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <PushPinIcon/>
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary="Current pin"
                                                secondary={member.ipfsPeerRootCid || '-'}
                                            />
                                        </ListItem>
                                        <ListItem alignItems="flex-start">
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <FilePresentIcon/>
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary="# of files"
                                                secondary={member.fileCount}
                                            />
                                        </ListItem>
                                    </Typography>
                                </CardContent>
                                <CardActions style={{justifyContent: 'flex-end'}}>
                                    {member && member.ipfsPeerRootCid && (
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            startIcon={<ExploreOutlinedIcon/>}
                                            onClick={(event) => {
                                                event.preventDefault();
                                                navigate(`/ipfs/cid/meta/${member.ipfsPeerRootCid}`)
                                            }}
                                            href={`/ipfs/cid/meta/${member.ipfsPeerRootCid}`}
                                        >
                                            Browse files
                                        </Button>
                                    )}
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            )}
        </div>
    );
}

export default FederationMembersView;
