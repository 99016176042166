import React from 'react';
import { CircularProgress, Typography } from '@material-ui/core';
import useStyles from "../styles/loadingStyles";

const LoadingErrorComponent = ({ isLoading, error }) => {
    const classes = useStyles();

    return (
        <div>
            {isLoading && (
                <div className={classes.loading}>
                    <CircularProgress/>
                </div>
            )}
            {error && (
                <Typography variant="body1" className={classes.error}>
                    Error: {error.message}
                </Typography>
            )}
        </div>
    );
};

export default LoadingErrorComponent;
