import React from "react";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import {Helmet} from 'react-helmet';
import TrileBreadcrumbs from "./components/TrileBreadcrumbsComponent";
import TrileRouterComponent from "./components/TrileRouterComponent";
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import {styled} from '@mui/material/styles';
import {Backdrop, createTheme, CssBaseline, ThemeProvider} from "@mui/material";
import Diversity2OutlinedIcon from '@mui/icons-material/Diversity2Outlined';
import ExploreOutlinedIcon from "@mui/icons-material/ExploreOutlined";
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from '@mui/icons-material/Home';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import DrawerListItemComponent from "./components/DrawerListItemComponent";
import projectConfiguration from "./configuration/ProjectConfiguration";

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth, transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp, duration: theme.transitions.duration.enteringScreen,
    }), overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp, duration: theme.transitions.duration.leavingScreen,
    }), overflowX: 'hidden', width: `calc(${theme.spacing(7)} + 1px)`, [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1), // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1, transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp, duration: theme.transitions.duration.leavingScreen,
    }), ...(open && {
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp, duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(({theme, open}) => ({
    width: drawerWidth, flexShrink: 0, whiteSpace: 'nowrap', boxSizing: 'border-box', ...(open && {
        ...openedMixin(theme), '& .MuiDrawer-paper': openedMixin(theme),
    }), ...(!open && {
        ...closedMixin(theme), '& .MuiDrawer-paper': closedMixin(theme),
    }),
}),);

const theme = createTheme({
    palette: {
        primary: {
            main: '#488ac6',
        },
        secondary: {
            main: '#ffcf33',
        }
    },
});


function App() {
    const [open, setOpen] = React.useState(false);

    const handleDrawerSwitch = () => {
        setOpen(!open);
    };

    return (<div>
        <Helmet>
            <title>
                {`${projectConfiguration.federationName}'s Trile federation`}
            </title>
            <meta name="description" content="Distributed & public file sharing"/>
            <meta httpEquiv="content-language" content="en"/>
            <link rel="icon" type="image/png" href="/favicon.ico?" sizes="16x16"/>
        </Helmet>
        <ThemeProvider theme={theme}>
            <Box x={{flexGrow: 1}}>
                <CssBaseline/>
                <AppBar position="fixed" open={open}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerSwitch}
                            edge="start"
                            sx={{
                                marginRight: 5, ...(open && {display: 'none'}),
                            }}
                        >
                            {open && (<CloseIcon/>)}
                            {!open && (<MenuIcon/>)}
                        </IconButton>
                        <TrileBreadcrumbs/>
                    </Toolbar>
                </AppBar>
                <Drawer variant="permanent" open={open}>
                    <DrawerHeader>
                    </DrawerHeader>
                    <Divider/>
                    <List>
                        <DrawerListItemComponent
                            title="Home"
                            IconComponent={HomeIcon}
                            open={open}
                            location={`/`}
                        />
                        <Divider/>
                        <DrawerListItemComponent
                            title="Federation members"
                            IconComponent={Diversity2OutlinedIcon}
                            open={open}
                            location={`/federation/members`}
                        />
                        <Divider/>
                        <DrawerListItemComponent
                            title="Docs"
                            IconComponent={HelpOutlineIcon}
                            open={open}
                            location={`https://trile.link`}
                        />
                        <Divider/>

                    </List>
                </Drawer>
                <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1}}
                    open={open}
                    onClick={handleDrawerSwitch}
                />
                <TrileRouterComponent/>
                {/*<WebSocketComponent />*/}
            </Box>
        </ThemeProvider>
    </div>);
}

export default App;
