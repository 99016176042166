import React from 'react';
import {Avatar} from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import ImageIcon from '@mui/icons-material/Image';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import imageExtensions from 'image-extensions';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import IpfsApiUrls from "../utils/IpfsApiUrls";

const iconMap = {
    folder: <FolderIcon/>,
    image: <ImageIcon/>,
    pdf: <PictureAsPdfIcon/>,
    video: <OndemandVideoIcon/>,
    default: <InsertDriveFileIcon/>,
};

const videoExtensions = ['mp4', 'mov', 'wmv', 'flv', 'avi', 'mkv', 'webm'];

const getFileTypeIcon = (ipfsCidMetadata, preview) => {
    const fileName = ipfsCidMetadata.originalName || ipfsCidMetadata.name;
    const fileType = ipfsCidMetadata.type;

    if (fileType.hasOwnProperty("Folder")) {
        return iconMap.folder;
    }

    const extension = fileName.split('.').pop().toLowerCase();

    if (imageExtensions.includes(extension) && !preview) {
        return iconMap.image;
    } else if (imageExtensions.includes(extension) && preview) {
        return <Avatar
            variant="rounded"
            src={IpfsApiUrls.Cid(ipfsCidMetadata.cid)}
            alt={ipfsCidMetadata.name}
        />
    } else if (extension === 'pdf') {
        return iconMap.pdf;
    } else if (videoExtensions.includes(extension)) {
        return iconMap.video;
    }

    return iconMap.default;
};


export const IpfsCidSearchResultItemIconComponent = ({ipfsCidMetadata, preview}) => {
    const fileIcon = getFileTypeIcon(ipfsCidMetadata, preview);
    if (fileIcon.type === Avatar) {
        return fileIcon;
    } else {
        return <Avatar sx={{marginRight: 2}}>{fileIcon}</Avatar>;
    }
};
