import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Container,
    CssBaseline,
    Grid,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import BackendApiUrls from '../utils/BackendApiUrls';
import axios from 'axios';
import Diversity2OutlinedIcon from '@mui/icons-material/Diversity2Outlined';
import {useNavigate} from 'react-router-dom';
import {IpfsCidSearchResultItemComponent} from "../components/IpfsCidSearchResultItemComponent";


function FilesView() {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchPage, setSearchPage] = useState(0);
    const [searchResults, setSearchResults] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [loadMoreDisabled, setLoadMoreDisabled] = useState(false);
    const navigate = useNavigate();

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const theme = useTheme();
    const isMdOrSmaller = useMediaQuery(theme.breakpoints.down('lg'));

    useEffect(() => {
        const handleEnterPress = (event) => {
            if (event.key === 'Enter') {
                triggerSearch();
            }
        };

        window.addEventListener('keydown', handleEnterPress);
        return () => window.removeEventListener('keydown', handleEnterPress);
    }, [searchTerm]);

    const triggerSearch = async () => {
        setIsSearching(true);
        try {
            const url = BackendApiUrls.IpfsCid.metadataSearch(searchTerm, 0);
            const response = await axios.get(url);
            const ipfsCidsSearchResults = response.data.ipfsCids;
            setSearchResults(ipfsCidsSearchResults);
            setLoadMoreDisabled(ipfsCidsSearchResults.length < response.data.pageSize);
            setSearchPage(0);
        } catch (error) {
            console.error('Search failed:', error);
            setSearchResults([]);
            setLoadMoreDisabled(true);
        } finally {
            setIsSearching(false);
        }
    };
    const triggerLoadMore = async () => {
        if (!loadMoreDisabled) {
            try {
                const url = BackendApiUrls.IpfsCid.metadataSearch(searchTerm, searchPage + 1);
                const response = await axios.get(url);
                const newResults = response.data.ipfsCids;
                setSearchResults((prevResults) => [...prevResults, ...newResults]);
                setLoadMoreDisabled(newResults.length < response.data.pageSize);
                setSearchPage(searchPage + 1);
            } catch (error) {
                console.error('Appending more search results failed:', error);
                setSearchResults([]);
                setLoadMoreDisabled(true);
            } finally {
                setIsSearching(false);
            }
        }
    };

    return (
        <Container>
            <CssBaseline/>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{backgroundColor: 'background.default', padding: 3}}
            >
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={10} md={6}>
                        <Card elevation={4}>
                            <CardContent>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    placeholder="Searching for 'The Answer to the Ultimate Question'?"
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                    autoFocus
                                />
                            </CardContent>
                            <Grid container spacing={2} justifyContent="flex-end" alignItems="center" p={2}>
                                <Grid item xs={6}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        startIcon={<SearchIcon/>}
                                        onClick={() => triggerSearch()}
                                        disabled={searchTerm.length < 1}
                                    >
                                        Search
                                    </Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        startIcon={<Diversity2OutlinedIcon/>}
                                        onClick={() => navigate(`/federation/members`)}
                                    >
                                        <Typography noWrap>
                                            {isMdOrSmaller ? 'Fed. members' : 'Federation members'}
                                        </Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    {isSearching && (
                        <Grid container spacing={2} justifyContent="center">
                            <Box
                                display="flex"
                                justifyContent="center"
                                mt={2}
                                sx={{m: 5}}
                            >
                            <CircularProgress/>
                            </Box>
                        </Grid>
                    )}
                    {!isSearching && searchResults.map((result, index) => (
                        <IpfsCidSearchResultItemComponent ipfsCidMetadata={result} index={index}/>
                    ))}
                </Grid>
            </Box>
            {!isSearching && searchResults.length > 0 && (
                <Box display="flex" justifyContent="center" mt={2}>
                    <Button
                        variant="contained"
                        disabled={loadMoreDisabled}
                        onClick={() => triggerLoadMore()}
                        sx={{marginBottom: 5}}
                    >
                        Load more
                    </Button>
                </Box>
            )}
        </Container>
    );
}

export default FilesView;
