import React from "react";
import {Link, useLocation} from "react-router-dom";
import {Breadcrumbs} from "@mui/material";
import projectConfiguration from "../configuration/ProjectConfiguration";

function TrileBreadcrumbs() {
    const location = useLocation();

    const path = location.pathname;

    const getIpfsCid = () => {
        const parts = path.split('/');
        return parts[4];
    }

    return (
        <>
            <Breadcrumbs separator=">" aria-label="breadcrumbs" color="inherit">
                <Link
                    style={{
                        textDecoration: 'none',
                        color: 'inherit',
                        marginLeft: '0.2em',
                        fontWeight: 'bold',
                        fontSize: 'larger'
                    }}
                    to="/"
                >
                    {`${projectConfiguration.federationName}'s Trile federation`}
                </Link>
                {(path === '/federation/members') && (
                    <Link
                        style={{
                            textDecoration: 'none',
                            color: 'inherit',
                            marginLeft: '0.2em',
                            fontWeight: 'bold',
                            fontSize: 'larger'
                        }}
                        to="/federation/members"
                    >
                        Federation Members
                    </Link>
                )}
                {(path.startsWith('/ipfs/cid/meta/')) && (
                    <Link to="/"
                          style={{
                              textDecoration: 'none',
                              color: 'inherit',
                              marginLeft: '0.2em',
                              fontWeight: 'bold',
                              fontSize: 'larger'
                          }}>
                        Files
                    </Link>
                )}
                {(path.startsWith('/ipfs/cid/meta/')) && (
                    <Link to="#"
                          style={{
                              textDecoration: 'none',
                              color: 'inherit',
                              marginLeft: '0.2em',
                              fontWeight: 'bold',
                              fontSize: 'larger'
                          }}>
                        {getIpfsCid()}
                    </Link>
                )}
            </Breadcrumbs>
        </>
    );
}

export default TrileBreadcrumbs;
