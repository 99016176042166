import ProjectConfig from "../configuration/ProjectConfiguration";

class IpfsApiUrls {
    static Cid(cid) {
        return `${ProjectConfig.ipfsGatewayUrl}/ipfs/${cid}`;
    }

    static CidForceDownload(ipfsCidMetadata) {
        return this.Cid(ipfsCidMetadata.cid) + `?download=true&filename=${ipfsCidMetadata.originalName}`;
    };
}

export default IpfsApiUrls;
