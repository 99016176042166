import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import {CardMedia} from "@mui/material";
import {Chip} from "@mui/material";
import Divider from "@mui/material/Divider";
import IpfsCidMetadataUtils from "../utils/IpfsCidMetadataUtils";
import axios from "axios";
import IpfsCidContentsComponent from "../components/IpfsCidContentsComponent";
import BackendApiUrls from "../utils/BackendApiUrls";
import LoadingErrorComponent from "../components/LoadingErrorComponent";
import IpfsApiUrls from "../utils/IpfsApiUrls";
import IpfsCidMetadataComponent from "../components/IpfsCidMetadataComponent";

function IpfsCidView() {
    const {cid} = useParams();
    const [ipfsCidMetadata, setIpfsCidMetadata] = useState();

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        axios.get(BackendApiUrls.IpfsCid.metadata(cid))
            .then((ipfsCidMetadataResponse) => {
                let ipfsCidMetadata = ipfsCidMetadataResponse.data;
                setIpfsCidMetadata(ipfsCidMetadata);
            })
            .catch((error) => {
                console.error('Error fetching member cids:', error);
                setError(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [cid]);

    let previewComponent = <>
        <Card>
            <Divider variant="middle" sx={{paddingY: 1}}>
                <Chip label="Preview" variant="outlined"/>
            </Divider>
            {ipfsCidMetadata && IpfsCidMetadataUtils.isFolder(ipfsCidMetadata) && (
                <IpfsCidContentsComponent ipfsCidsFlatParam={ipfsCidMetadata}/>
            )}
            {ipfsCidMetadata && IpfsCidMetadataUtils.isImage(ipfsCidMetadata) && (
                <CardMedia
                    component="img"
                    style={{height: '100%', width: '100%'}}
                    image={IpfsApiUrls.Cid(ipfsCidMetadata.cid)}
                    title={ipfsCidMetadata.originalName}
                />
            )}
            {ipfsCidMetadata && !IpfsCidMetadataUtils.isImage(ipfsCidMetadata) && IpfsCidMetadataUtils.isBinary(ipfsCidMetadata) && (
                <Typography component="h1" align="center" sx={{paddingBottom: 1}}>
                    No preview available
                </Typography>
            )}
        </Card>
    </>;

    return (
        <>
            <LoadingErrorComponent isLoading={isLoading} error={error}/>
            {ipfsCidMetadata && (
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={5} xl={4}>
                        <IpfsCidMetadataComponent ipfsCidMetadataParam={ipfsCidMetadata}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={7} xl={8}>
                        {previewComponent}
                    </Grid>
                </Grid>
            )}
        </>
    );
}

export default IpfsCidView;
