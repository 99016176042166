import React from 'react';
import { Link } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';

function DrawerListItemComponent({ title, IconComponent, open, location }) {
    return (
        <ListItem
            key={title}
            disablePadding
            sx={{
                display: 'block', color: 'inherit',
            }}
            component={Link}
            to={`${location}`}
        >
            <ListItemButton
                sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    color: 'inherit',
                }}
            >
                <Tooltip title={title}>
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                            color: 'inherit',
                        }}
                    >
                        <IconComponent />
                    </ListItemIcon>
                </Tooltip>
                <ListItemText
                    primary={title}
                    sx={{
                        opacity: open ? 1 : 0, color: 'primary',
                    }}
                />
            </ListItemButton>
        </ListItem>
    );
}

export default DrawerListItemComponent;