import ProjectConfig from "../configuration/ProjectConfiguration";

class BackendApiUrls {
    static IpfsCid = class {
        static metadata(cid) {
            return `${ProjectConfig.backendUrl}/api/ipfs/meta/${cid}`;
        }

        static metadataSearch(term, page) {
            return `${ProjectConfig.backendUrl}/api/ipfs/meta?q=${term}&page=${page}`;
        }

        static fileTree(cid) {
            return `${ProjectConfig.backendUrl}/api/ipfs/file-tree/${cid}`;
        }

        static nodeTree(cid) {
            return `${ProjectConfig.backendUrl}/api/ipfs/node-tree/${cid}`;
        }

        static flat(cid) {
            return `${ProjectConfig.backendUrl}/api/ipfs/flat/${cid}`;
        }
    }

    static Federation = class {
        static Members() {
            return `${ProjectConfig.backendUrl}/api/federation/members`;
        }
    }
}

export default BackendApiUrls;
