import * as React from 'react';
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import {CardActions, List} from "@mui/material";
import {Chip, ListItem, ListItemText} from "@mui/material";
import Button from "@mui/material/Button";
import {filesize} from "filesize";
import DownloadIcon from "@mui/icons-material/Download";
import ShareIcon from "@mui/icons-material/Share";
import Divider from "@mui/material/Divider";
import IpfsApiUrls from "../utils/IpfsApiUrls";
import DateUtils from "../utils/DateUtils";
import IpfsCidMetadataUtils from "../utils/IpfsCidMetadataUtils";

function IpfsCidMetadataComponent({ipfsCidMetadataParam}) {
    const [ipfsCidMetadata, setIpfsCidMetadataParam] = React.useState(ipfsCidMetadataParam)

    const getCopiesLabel = (ipfsCidExample) => {
        return ipfsCidExample.copies.hard + " hard & " + ipfsCidExample.copies.soft + " soft";
    }

    React.useEffect(() => {
        setIpfsCidMetadataParam(ipfsCidMetadataParam)
    }, [ipfsCidMetadataParam]);

    let originalNamePrefix = IpfsCidMetadataUtils.isBinary(ipfsCidMetadata) ? 'file' : 'folder';

    let cardActions = <CardActions>
        {ipfsCidMetadata && IpfsCidMetadataUtils.isBinary(ipfsCidMetadata) && (
            <Button
                variant="outlined"
                color="primary"
                startIcon={<DownloadIcon/>}
                onClick={() => window.open(IpfsApiUrls.CidForceDownload(ipfsCidMetadata))}
                href={IpfsApiUrls.CidForceDownload(ipfsCidMetadata)}
            >
                Download
            </Button>
        )}
        {ipfsCidMetadata && (
            <Button
                variant="outlined"
                color="primary"
                startIcon={<ShareIcon/>}
                onClick={() => window.open(IpfsApiUrls.Cid(ipfsCidMetadata.cid))}
                href={IpfsApiUrls.Cid(ipfsCidMetadata.cid)}
            >
                Share
            </Button>
        )}
    </CardActions>;

    let advanceInformationListItemText = <>
        {ipfsCidMetadata && ipfsCidMetadata.names.length !== 0 && (
            <>
                <Divider variant="middle" sx={{paddingY: 1}}>
                    <Chip label="Advanced" size="small" variant="outlined"/>
                </Divider>
                <ListItemText
                    primary="Other names"
                    secondary={
                        <List
                            dense={true}
                            sx={{
                                listStyleType: 'disc',
                                listStylePosition: 'inside'
                            }}
                        >
                            {ipfsCidMetadata.names.map((otherName) => (
                                    <ListItem sx={{display: 'list-item'}}>
                                        {otherName}
                                    </ListItem>
                                )
                            )}
                        </List>
                    }
                />
            </>
        )}</>;
    let cardContent = <CardContent>
        {ipfsCidMetadata && (
            <>
                <ListItemText
                    primary={`Original ${originalNamePrefix} name`}
                    secondary={ipfsCidMetadata.originalName !== "" ? ipfsCidMetadata.originalName : "-"}
                />
                <ListItemText
                    primary="Cid"
                    secondary={ipfsCidMetadata.cid}
                />
                <ListItemText
                    primary="Size"
                    secondary={filesize(ipfsCidMetadata.size)}
                />
                <ListItemText
                    primary="Added on"
                    secondary={DateUtils.getYYYYMMDD(ipfsCidMetadata.timestamp)}
                />
                <ListItemText
                    primary="Copies"
                    secondary={getCopiesLabel(ipfsCidMetadata)}
                />
                {advanceInformationListItemText}
            </>
        )}
    </CardContent>;

    return (
        <Card>
            {cardContent}
            {cardActions}
        </Card>
    );
}

export default IpfsCidMetadataComponent;
