import React from 'react';
import {Link} from 'react-router-dom';
import {Box, Card, CardActionArea, CardContent, Grid, Tooltip, Typography} from '@mui/material';
import {filesize} from "filesize";
import {IpfsCidSearchResultItemIconComponent} from "./IpfsCidSearchResultItemIconComponent";

export function IpfsCidSearchResultItemComponent({ipfsCidMetadata, index}) {

    return <Grid item xs={12} md={10} key={index}>
        <Card sx={{marginBottom: 2}}>
            <CardActionArea component={Link} to={`/ipfs/cid/meta/${ipfsCidMetadata.cid}`}>
                <CardContent>
                    <Box display="flex" alignItems="center">
                        <IpfsCidSearchResultItemIconComponent ipfsCidMetadata={ipfsCidMetadata} preview={false}/>
                        <Box>
                            <Typography gutterBottom variant="h5" component="div"
                                        sx={{fontWeight: "bold"}}>
                                {ipfsCidMetadata.originalName}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                CID: {ipfsCidMetadata.cid}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Size: {filesize(ipfsCidMetadata.size)} bytes
                                {ipfsCidMetadata.type.hasOwnProperty("Folder") ? (` in ${ipfsCidMetadata.fileCount} files`) : ""}
                            </Typography>
                            <Box>
                                <Tooltip title="Hard/Soft copies" placement="left">
                                    <Typography variant="body2" color="text.secondary">
                                        ({ipfsCidMetadata.copies?.hard || 0}/{ipfsCidMetadata.copies?.soft || 0})
                                    </Typography>
                                </Tooltip>
                            </Box>
                        </Box>
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    </Grid>
}