class IpfsCidMetadataUtils {
    static isBinary = function(ipfsCidMetadata) {
        return ipfsCidMetadata.type.File;
    }

    static isFolder = function(ipfsCidMetadata) {
        return ipfsCidMetadata.type.Folder;
    }

    static isImage = function(ipfsCidMetadata) {
        const filename = ipfsCidMetadata.originalName? ipfsCidMetadata.originalName : (ipfsCidMetadata.name? ipfsCidMetadata.name : "");
        const fileExtension = filename.split('.').pop().toLowerCase();
        return ['jpg', 'jpeg', 'gif', 'png'].includes(fileExtension);
    }
}
export default IpfsCidMetadataUtils;