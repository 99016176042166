class DateUtils {
    static getYYYYMMDD = function(timestamp) {
        const date = new Date(timestamp);
        return date.toISOString()
            .replace(/T/, ' ')
            .replace(/\..+/, '')
            .substring(0, 16);
    }
}

export default DateUtils;
