import React from "react";
import {Route, Routes} from "react-router-dom";
import FederationMembersView from "../views/FederationMembersView";
import IpfsCidView from "../views/IpfsCidView";
import WebSocketMessageDisplay from "./WebsocketMessageDisplay";
import FilesView from "../views/FilesView";

function TrileRouterComponent() {
    return (
        <div style={{marginTop: '86px', marginLeft: '86px'}}>
            <Routes>
                <Route
                    path="/federation/members"
                    element={<FederationMembersView/>}
                />
                <Route
                    path="/ipfs/cid/meta/:cid"
                    element={<IpfsCidView/>}
                />
                <Route
                    path="/live-stream"
                    element={<WebSocketMessageDisplay/>}
                />
                <Route
                    path="/"
                    element={<FilesView/>}
                />
            </Routes>
        </div>
    );
}

export default TrileRouterComponent;
