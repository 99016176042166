// styles.js
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    loading: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
    },
    error: {
        color: theme.palette.error.main,
        textAlign: "center",
    },
}));

export default useStyles;
