import * as React from 'react';
import {useNavigate} from 'react-router-dom';
import {Alert, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Snackbar} from "@mui/material";
import {filesize} from "filesize";
import DownloadIcon from '@mui/icons-material/Download';
import ShareIcon from '@mui/icons-material/Share';
import IconButton from "@material-ui/core/IconButton";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import IpfsCidMetadataUtils from "../utils/IpfsCidMetadataUtils";
import IpfsApiUrls from "../utils/IpfsApiUrls";
import {IpfsCidSearchResultItemIconComponent} from "./IpfsCidSearchResultItemIconComponent";

function IpfsCidContentsComponent({ipfsCidsFlatParam}) {
    const navigate = useNavigate();

    const [ipfsCidsFlat, setIpfsCidsFlat] = React.useState(ipfsCidsFlatParam)


    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    }

    const handleFolderClick = (ipfsCid) => {
        navigate(`/ipfs/cid/meta/${ipfsCid.cid}`);
    };
    const handleFileClick = (ipfsCid) => {
        navigate(`/ipfs/cid/meta/${ipfsCid.cid}`);
    };

    const handleCopyToClipboard = async (file) => {
        setOpenSnackbar(true);

        function getFolderUrl() {
            return window.location.origin + window.location.pathname + '/' + file.name;
        }

        let urlToShare = IpfsCidMetadataUtils.isFolder(file) ? getFolderUrl() : IpfsApiUrls.CidForceDownload(file);
        try {
            await navigator.clipboard.writeText(urlToShare);
        } catch (error) {
            console.error('Error copying text to clipboard:', error);
        }
    };

    React.useEffect(() => {
        setIpfsCidsFlat(ipfsCidsFlatParam)
    }, [ipfsCidsFlatParam]);

    function listItemAvatar(ipfsCidMetadata) {
        return <>
            <ListItemAvatar sx={{alignItems: 'center'}}>
                <IpfsCidSearchResultItemIconComponent ipfsCidMetadata={ipfsCidMetadata} preview={true}/>
            </ListItemAvatar>
        </>;
    }

    return (
        <>
            {ipfsCidsFlat && (
                <List>
                    {ipfsCidsFlat.contents.map((ipfsCidContent) => (
                        <ListItem
                            secondaryAction={
                                <>
                                    {IpfsCidMetadataUtils.isBinary(ipfsCidContent) && (
                                        <IconButton>
                                            <DownloadIcon
                                                onClick={() => window.open(IpfsApiUrls.CidForceDownload(ipfsCidContent))}/>
                                        </IconButton>
                                    )}
                                    {IpfsCidMetadataUtils.isFolder(ipfsCidContent) && (
                                        <IconButton>
                                            <InfoOutlinedIcon
                                                onClick={() => navigate(`/ipfs/cid/meta/${ipfsCidContent.cid}`)}
                                            />
                                        </IconButton>
                                    )}
                                    <IconButton>
                                        <ShareIcon onClick={() => handleCopyToClipboard(ipfsCidContent)}/>
                                    </IconButton>
                                </>
                            }
                            disablePadding
                        >
                            <ListItemButton
                                onClick={() => IpfsCidMetadataUtils.isFolder(ipfsCidContent) ? handleFolderClick(ipfsCidContent) : handleFileClick(ipfsCidContent) }
                            >
                                {listItemAvatar(ipfsCidContent)}
                                <ListItemText primary={ipfsCidContent.name}
                                              secondary={IpfsCidMetadataUtils.isBinary(ipfsCidContent) ? filesize(ipfsCidContent.size) : ipfsCidContent.fileCount + ' files'}/>
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            )}

            <Snackbar open={openSnackbar} autoHideDuration={2000} onClose={handleCloseSnackbar}>
                <Alert
                    onClose={handleCloseSnackbar}
                    severity="success"
                    variant="filled"
                    sx={{width: '100%'}}
                >
                    Link copied to clipboard
                </Alert>
            </Snackbar>
        </>
    );
}

export default IpfsCidContentsComponent;
